import { ComponentProps, JSX } from "solid-js";
import c from "class-c";

import styles from "./ScrollView.module.scss";

declare namespace ScrollView {
  type Props = ComponentProps<"div"> & {
    children: JSX.Element;
  };
}

function ScrollView({
  class: className,
  children,
  ...props
}: D<ScrollView.Props>) {
  return (
    <div class={c`${styles["scroll-view"]} ${className}`} {...props}>
      {children}
    </div>
  );
}

export default ScrollView;
